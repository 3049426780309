const Ankerslogen = ({ isMobile, ...props }: { isMobile: boolean }) => {
  return (
    <svg
      viewBox="0 0 1443 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M316.805 96.0415L343.454 138.489L368.011 138.532L330.172 78.3193L316.805 96.0415Z"
        fill="#00BEFA"
      />
      <path
        d="M543.453 91.7711C556.607 81.6929 562.372 71.2304 561.433 57.7359C560.066 38.0921 544.179 24.8965 526.071 24.8965H474.096V138.532H494.766V91.7711H519.152L548.364 138.532H572.75L543.453 91.7711ZM541.403 57.7786C541.403 66.4903 535.296 71.6148 525.943 71.6148H494.766V44.9674H525.943C537.73 44.9674 541.403 53.0812 541.403 57.7786Z"
        fill="#00BEFA"
      />
      <path
        d="M360.536 24.854H334.314L280.289 96.6823V24.854H259.533V128.454C259.533 132.255 261.754 136.995 267.69 138.319C270.039 138.874 274.78 138.319 277.598 134.561C277.94 134.091 360.579 24.854 360.579 24.854"
        fill="#00BEFA"
      />
      <path
        d="M447.276 71.6152H406.49V91.7289H447.276V71.6152Z"
        fill="#00BEFA"
      />
      <path
        d="M457.782 24.854H376.809V128.411C376.809 134.006 381.336 138.532 386.93 138.532H457.782V118.461H397.522V45.0103H457.782V24.8967V24.854Z"
        fill="#00BEFA"
      />
      <path
        d="M218.022 24.854V96.6823L163.997 24.854H137.604V138.447H158.317V52.0565C182.831 84.4262 220.456 134.262 220.712 134.561C223.531 138.276 228.272 138.874 230.621 138.319C236.557 136.952 238.778 132.255 238.778 128.454V24.854H218.022Z"
        fill="#00BEFA"
      />
      <path
        d="M53.1282 31.0034L0 138.532H23.1475L63.1644 57.8216L103.139 138.532H126.073L73.3288 31.345C71.3643 27.3735 67.3498 24.854 62.9082 24.854C58.7228 24.854 54.9219 27.2454 53.0855 30.9607"
        fill="#00BEFA"
      />
      <path
        d="M81.9557 122.433H68.2893C68.2893 122.433 68.0758 122.305 68.0758 122.177L70.7237 92.9673C70.7237 92.7537 70.4674 92.6256 70.3393 92.7964L40.8711 131.358C40.8711 131.358 40.8711 131.7 41.0419 131.7H54.751C54.751 131.7 54.9646 131.828 54.9646 131.956L52.3167 161.166C52.3167 161.379 52.573 161.507 52.7011 161.337L82.1693 122.775C82.1693 122.775 82.1693 122.433 81.9984 122.433"
        fill="#00BEFA"
      />
      <path d="M689.3 24.8965H685.029V138.233H689.3V24.8965Z" fill="#00BEFA" />
      <path
        d="M1043.52 68.583C1037.45 68.583 1035.02 65.6791 1035.02 59.7006V25.0248C1035.02 24.6405 1035.27 24.3843 1035.66 24.3843H1041.59C1041.98 24.3843 1042.24 24.6405 1042.24 25.0248V59.3162C1042.24 61.4514 1043.09 62.1347 1044.93 62.1347H1046.34C1046.72 62.1347 1046.98 62.3909 1046.98 62.7753V67.9425C1046.98 68.3268 1046.72 68.583 1046.34 68.583H1043.52Z"
        fill="#00BEFA"
      />
      <path
        d="M801.621 95.1874C801.621 94.8031 801.877 94.5469 802.262 94.5469H807.985C808.582 94.5469 808.881 94.7604 809.095 95.1874L821.138 122.176H821.395L833.267 95.1874C833.481 94.7177 833.78 94.5469 834.378 94.5469H840.058C840.442 94.5469 840.699 94.8031 840.699 95.1874V137.721C840.699 138.105 840.442 138.361 840.058 138.361H834.463C834.079 138.361 833.823 138.105 833.823 137.721V110.903H833.566L824.299 131.529C824.043 132.126 823.658 132.383 823.06 132.383H819.345C818.747 132.383 818.362 132.126 818.106 131.529L808.839 110.903H808.582V137.721C808.582 138.105 808.326 138.361 807.942 138.361H802.347C801.963 138.361 801.707 138.105 801.707 137.721V95.1874H801.621Z"
        fill="#00BEFA"
      />
      <path
        d="M849.07 130.461C848.344 128.283 848.045 126.191 848.045 122.774C848.045 119.358 848.387 117.308 849.07 115.087C850.821 109.664 855.561 106.504 861.882 106.504C868.203 106.504 872.901 109.664 874.652 115.087C875.378 117.265 875.677 119.358 875.677 122.774C875.677 126.191 875.335 128.24 874.652 130.461C872.901 135.884 868.16 139.044 861.882 139.044C855.604 139.044 850.778 135.884 849.07 130.461ZM867.69 128.539C868.203 126.916 868.331 125.465 868.331 122.817C868.331 120.169 868.203 118.76 867.69 117.095C866.793 114.532 864.786 113.038 861.882 113.038C858.978 113.038 856.928 114.532 856.031 117.095C855.519 118.76 855.391 120.169 855.391 122.817C855.391 125.465 855.519 126.959 856.031 128.539C856.928 131.101 858.978 132.596 861.882 132.596C864.786 132.596 866.793 131.101 867.69 128.539Z"
        fill="#00BEFA"
      />
      <path
        d="M890.026 135.372H889.983V137.678C889.983 138.062 889.727 138.318 889.343 138.318H883.406C883.022 138.318 882.766 138.062 882.766 137.678V95.1445C882.766 94.7601 883.022 94.5039 883.406 94.5039H889.343C889.727 94.5039 889.983 94.7601 889.983 95.1445V110.219H890.026C891.521 108.084 894.083 106.546 898.268 106.546C903.564 106.546 907.408 109.066 909.031 114.02C909.8 116.411 910.141 118.589 910.141 122.774C910.141 126.959 909.8 129.137 909.031 131.528C907.408 136.482 903.564 139.002 898.268 139.002C894.083 139.002 891.521 137.464 890.026 135.329V135.372ZM902.155 128.624C902.625 127.215 902.795 125.678 902.795 122.817C902.795 119.956 902.582 118.418 902.155 117.009C901.301 114.447 899.336 112.995 896.432 112.995C893.528 112.995 891.478 114.404 890.624 117.009C890.154 118.418 889.983 119.956 889.983 122.817C889.983 125.678 890.154 127.215 890.624 128.624C891.478 131.187 893.442 132.639 896.432 132.639C899.422 132.639 901.343 131.229 902.155 128.624Z"
        fill="#00BEFA"
      />
      <path
        d="M918 101.422C917.616 101.422 917.359 101.166 917.359 100.782V95.1874C917.359 94.8031 917.616 94.5469 918 94.5469H923.936C924.321 94.5469 924.577 94.8031 924.577 95.1874V100.782C924.577 101.166 924.321 101.422 923.936 101.422H918ZM918 138.319C917.616 138.319 917.359 138.062 917.359 137.678V107.913C917.359 107.529 917.616 107.273 918 107.273H923.936C924.321 107.273 924.577 107.529 924.577 107.913V137.678C924.577 138.062 924.321 138.319 923.936 138.319H918Z"
        fill="#00BEFA"
      />
      <path
        d="M941.36 138.746C935.296 138.746 932.861 135.842 932.861 129.863V95.1874C932.861 94.8031 933.118 94.5469 933.502 94.5469H939.438C939.823 94.5469 940.079 94.8031 940.079 95.1874V129.479C940.079 131.614 940.933 132.297 942.77 132.297H944.179C944.563 132.297 944.819 132.554 944.819 132.938V138.105C944.819 138.489 944.563 138.746 944.179 138.746H941.36Z"
        fill="#00BEFA"
      />
      <path
        d="M950.329 130.418C949.689 128.411 949.219 126.019 949.219 122.817C949.219 119.614 949.603 117.137 950.244 115.13C951.995 109.706 956.735 106.546 962.928 106.546C969.12 106.546 973.946 109.835 975.697 115.13C976.423 117.308 976.722 119.443 976.722 124.482C976.722 124.866 976.466 125.123 975.996 125.123H956.863C956.607 125.123 956.479 125.251 956.479 125.507C956.479 126.404 956.693 127.172 956.949 127.898C957.974 130.93 960.408 132.596 963.91 132.596C967.412 132.596 969.633 131.315 971.17 129.649C971.512 129.308 971.811 129.265 972.153 129.521L975.954 132.852C976.295 133.108 976.295 133.45 976.039 133.749C973.391 136.824 969.035 139.044 963.27 139.044C956.693 139.044 952.08 135.841 950.329 130.418ZM969.078 116.71C968.224 114.19 965.917 112.696 963.013 112.696C960.109 112.696 957.718 114.19 956.906 116.71C956.65 117.479 956.522 118.247 956.522 119.358C956.522 119.614 956.65 119.742 956.906 119.742H969.078C969.334 119.742 969.462 119.614 969.462 119.358C969.462 118.247 969.334 117.479 969.078 116.71Z"
        fill="#00BEFA"
      />
      <path
        d="M998.502 116.454C998.502 109.493 998.758 107.315 999.655 104.582C1001.92 97.4506 1007.47 93.8208 1014.86 93.8208C1021.86 93.8208 1027.16 97.4079 1029.68 103.216C1029.81 103.557 1029.68 103.856 1029.34 103.984L1023.87 106.632C1023.49 106.846 1023.14 106.632 1023.02 106.29C1021.61 103.216 1019.13 100.611 1014.82 100.611C1010.5 100.611 1008.2 102.874 1006.96 106.418C1006.36 108.212 1006.19 109.963 1006.19 116.411C1006.19 122.86 1006.4 124.61 1006.96 126.404C1008.2 129.948 1010.63 132.212 1014.82 132.212C1019 132.212 1021.56 129.65 1023.02 126.532C1023.14 126.191 1023.49 126.02 1023.87 126.191L1029.34 128.838C1029.68 128.966 1029.81 129.308 1029.68 129.607C1027.16 135.415 1021.86 139.002 1014.86 139.002C1007.43 139.002 1001.92 135.415 999.655 128.24C998.758 125.55 998.502 123.329 998.502 116.369V116.454Z"
        fill="#00BEFA"
      />
      <path
        d="M1055.73 138.318C1055.35 138.318 1055.09 138.062 1055.09 137.678V119.785C1055.09 115.771 1053.08 113.037 1049.24 113.037C1045.4 113.037 1043.3 115.813 1043.3 119.785V137.678C1043.3 138.062 1043.05 138.318 1042.66 138.318H1036.73C1036.34 138.318 1036.09 138.062 1036.09 137.678V95.1445C1036.09 94.7601 1036.34 94.5039 1036.73 94.5039H1042.66C1043.05 94.5039 1043.3 94.7601 1043.3 95.1445V110.176H1043.35C1044.71 108.255 1047.4 106.589 1051.55 106.589C1058.17 106.589 1062.31 111.5 1062.31 118.119V137.72C1062.31 138.105 1062.05 138.361 1061.67 138.361H1055.73V138.318Z"
        fill="#00BEFA"
      />
      <path
        d="M1088.7 138.319C1088.32 138.319 1088.06 138.062 1088.06 137.678V135.415H1087.97C1086.48 137.55 1083.58 139.002 1079.22 139.002C1073.54 139.002 1068.8 136.055 1068.8 129.522C1068.8 122.988 1073.58 119.657 1081.23 119.657H1087.55C1087.8 119.657 1087.93 119.529 1087.93 119.273V117.778C1087.93 114.234 1086.27 112.611 1081.06 112.611C1077.6 112.611 1075.46 113.593 1073.97 114.746C1073.62 115.002 1073.24 114.96 1073.07 114.618L1070.68 110.347C1070.46 110.006 1070.55 109.707 1070.81 109.493C1073.33 107.7 1076.74 106.461 1081.82 106.461C1091.31 106.461 1094.77 109.664 1094.77 117.266V137.55C1094.77 137.934 1094.51 138.191 1094.12 138.191H1088.7V138.319ZM1087.93 127.899V125.507C1087.93 125.251 1087.8 125.123 1087.55 125.123H1082.38C1077.72 125.123 1075.67 126.404 1075.67 129.308C1075.67 131.87 1077.55 133.194 1081.01 133.194C1085.45 133.194 1087.89 131.443 1087.89 127.899H1087.93Z"
        fill="#00BEFA"
      />
      <path
        d="M1103.69 138.318C1103.31 138.318 1103.05 138.062 1103.05 137.678V107.913C1103.05 107.529 1103.31 107.272 1103.69 107.272H1109.63C1110.01 107.272 1110.27 107.529 1110.27 107.913V110.561H1110.31C1111.81 108.169 1114.45 106.546 1118.38 106.546C1120.82 106.546 1123.29 107.529 1124.87 109.109C1125.22 109.45 1125.26 109.706 1125 110.005L1121.59 114.319C1121.33 114.66 1120.99 114.703 1120.69 114.447C1119.28 113.55 1117.78 112.952 1116.12 112.952C1112.06 112.952 1110.27 115.856 1110.27 120.81V137.635C1110.27 138.019 1110.01 138.276 1109.63 138.276H1103.69V138.318Z"
        fill="#00BEFA"
      />
      <path
        d="M1129.32 146.005C1129.06 145.664 1129.06 145.407 1129.4 145.108L1133.2 141.308C1133.54 140.966 1133.84 140.966 1134.18 141.308C1136.11 143.187 1138.37 144.212 1140.93 144.212C1145.71 144.212 1147.89 141.649 1147.89 136.781V133.877H1147.81C1146.4 136.055 1143.75 137.464 1139.74 137.464C1134.53 137.464 1130.77 134.817 1129.19 129.906C1128.42 127.514 1128.16 125.379 1128.16 121.963C1128.16 118.546 1128.42 116.497 1129.19 114.105C1130.81 109.152 1134.53 106.504 1139.74 106.504C1143.75 106.504 1146.36 107.913 1147.81 110.176H1147.89V107.87C1147.89 107.486 1148.15 107.23 1148.53 107.23H1154.47C1154.85 107.23 1155.11 107.486 1155.11 107.87V136.354C1155.11 145.237 1150.16 150.575 1140.63 150.575C1136.32 150.575 1131.75 148.781 1129.36 145.92L1129.32 146.005ZM1147.17 127.13C1147.68 125.721 1147.89 124.183 1147.89 122.048C1147.89 119.913 1147.68 118.418 1147.17 116.966C1146.31 114.447 1144.6 113.038 1141.61 113.038C1138.63 113.038 1136.96 114.447 1136.15 116.966C1135.64 118.461 1135.42 119.998 1135.42 122.048C1135.42 124.098 1135.59 125.721 1136.15 127.13C1137 129.692 1138.71 131.144 1141.61 131.144C1144.52 131.144 1146.31 129.735 1147.17 127.13Z"
        fill="#00BEFA"
      />
      <path
        d="M1164.17 101.422C1163.78 101.422 1163.53 101.166 1163.53 100.782V95.1874C1163.53 94.8031 1163.78 94.5469 1164.17 94.5469H1170.1C1170.49 94.5469 1170.74 94.8031 1170.74 95.1874V100.782C1170.74 101.166 1170.49 101.422 1170.1 101.422H1164.17ZM1164.17 138.319C1163.78 138.319 1163.53 138.062 1163.53 137.678V107.913C1163.53 107.529 1163.78 107.273 1164.17 107.273H1170.1C1170.49 107.273 1170.74 107.529 1170.74 107.913V137.678C1170.74 138.062 1170.49 138.319 1170.1 138.319H1164.17Z"
        fill="#00BEFA"
      />
      <path
        d="M1198.76 138.319C1198.37 138.319 1198.12 138.062 1198.12 137.678V119.785C1198.12 115.771 1196.11 113.038 1192.27 113.038C1188.42 113.038 1186.33 115.813 1186.33 119.785V137.678C1186.33 138.062 1186.07 138.319 1185.69 138.319H1179.75C1179.37 138.319 1179.11 138.062 1179.11 137.678V107.913C1179.11 107.529 1179.37 107.273 1179.75 107.273H1185.69C1186.07 107.273 1186.33 107.529 1186.33 107.913V110.177H1186.37C1187.74 108.255 1190.43 106.589 1194.57 106.589C1201.19 106.589 1205.34 111.5 1205.34 118.119V137.721C1205.34 138.105 1205.08 138.361 1204.7 138.361H1198.76V138.319Z"
        fill="#00BEFA"
      />
      <path
        d="M1213.28 146.005C1213.02 145.664 1213.02 145.407 1213.36 145.108L1217.16 141.308C1217.51 140.966 1217.81 140.966 1218.15 141.308C1220.07 143.187 1222.33 144.212 1224.89 144.212C1229.68 144.212 1231.86 141.649 1231.86 136.781V133.877H1231.77C1230.36 136.055 1227.71 137.464 1223.7 137.464C1218.49 137.464 1214.73 134.817 1213.15 129.906C1212.38 127.514 1212.12 125.379 1212.12 121.963C1212.12 118.546 1212.38 116.497 1213.15 114.105C1214.77 109.152 1218.49 106.504 1223.7 106.504C1227.71 106.504 1230.32 107.913 1231.77 110.176H1231.86V107.87C1231.86 107.486 1232.11 107.23 1232.5 107.23H1238.43C1238.82 107.23 1239.07 107.486 1239.07 107.87V136.354C1239.07 145.237 1234.12 150.575 1224.6 150.575C1220.28 150.575 1215.71 148.781 1213.32 145.92L1213.28 146.005ZM1231.13 127.13C1231.64 125.721 1231.86 124.183 1231.86 122.048C1231.86 119.913 1231.64 118.418 1231.13 116.966C1230.28 114.447 1228.57 113.038 1225.58 113.038C1222.59 113.038 1220.92 114.447 1220.11 116.966C1219.6 118.461 1219.39 119.998 1219.39 122.048C1219.39 124.098 1219.56 125.721 1220.11 127.13C1220.97 129.692 1222.67 131.144 1225.58 131.144C1228.48 131.144 1230.28 129.735 1231.13 127.13Z"
        fill="#00BEFA"
      />
      <path
        d="M1263.76 95.1874C1263.76 94.8031 1264.02 94.5469 1264.4 94.5469H1280.71C1289.98 94.5469 1294.38 99.2016 1294.38 106.205C1294.38 111.159 1292.07 114.02 1289.3 115.472V115.6C1291.99 116.625 1295.15 120.127 1295.15 125.465C1295.15 134.176 1289.68 138.361 1280.07 138.361H1264.4C1264.02 138.361 1263.76 138.105 1263.76 137.721V95.1874ZM1280.54 112.782C1284.43 112.782 1286.78 110.476 1286.78 107.059C1286.78 103.643 1284.39 101.251 1280.54 101.251H1271.79C1271.53 101.251 1271.4 101.38 1271.4 101.636V112.397C1271.4 112.653 1271.53 112.782 1271.79 112.782H1280.54ZM1271.79 131.657H1280.93C1285.2 131.657 1287.55 129.223 1287.55 125.55C1287.55 121.877 1285.16 119.486 1280.93 119.486H1271.79C1271.53 119.486 1271.4 119.614 1271.4 119.87V131.272C1271.4 131.529 1271.53 131.657 1271.79 131.657Z"
        fill="#00BEFA"
      />
      <path
        d="M1302.96 138.318C1302.58 138.318 1302.32 138.062 1302.32 137.678V107.913C1302.32 107.529 1302.58 107.272 1302.96 107.272H1308.9C1309.29 107.272 1309.54 107.529 1309.54 107.913V110.561H1309.58C1311.08 108.169 1313.73 106.546 1317.66 106.546C1320.09 106.546 1322.57 107.529 1324.15 109.109C1324.49 109.45 1324.53 109.706 1324.28 110.005L1320.86 114.319C1320.6 114.66 1320.26 114.703 1319.96 114.447C1318.55 113.55 1317.06 112.952 1315.39 112.952C1311.34 112.952 1309.54 115.856 1309.54 120.81V137.635C1309.54 138.019 1309.29 138.276 1308.9 138.276H1302.96V138.318Z"
        fill="#00BEFA"
      />
      <path
        d="M1347.89 138.319C1347.51 138.319 1347.25 138.062 1347.25 137.678V135.415H1347.17C1345.67 137.55 1342.77 139.002 1338.41 139.002C1332.73 139.002 1327.99 136.055 1327.99 129.522C1327.99 122.988 1332.78 119.657 1340.42 119.657H1346.74C1347 119.657 1347.13 119.529 1347.13 119.273V117.778C1347.13 114.234 1345.46 112.611 1340.25 112.611C1336.79 112.611 1334.65 113.593 1333.16 114.746C1332.82 115.002 1332.43 114.96 1332.26 114.618L1329.87 110.347C1329.66 110.006 1329.74 109.707 1330 109.493C1332.52 107.7 1335.94 106.461 1341.02 106.461C1350.5 106.461 1353.96 109.664 1353.96 117.266V137.55C1353.96 137.934 1353.7 138.191 1353.32 138.191H1347.89V138.319ZM1347.13 127.899V125.507C1347.13 125.251 1347 125.123 1346.74 125.123H1341.57C1336.92 125.123 1334.87 126.404 1334.87 129.308C1334.87 131.87 1336.75 133.194 1340.21 133.194C1344.65 133.194 1347.08 131.443 1347.08 127.899H1347.13Z"
        fill="#00BEFA"
      />
      <path
        d="M1381.89 138.319C1381.5 138.319 1381.25 138.062 1381.25 137.678V119.785C1381.25 115.771 1379.24 113.038 1375.4 113.038C1371.55 113.038 1369.46 115.813 1369.46 119.785V137.678C1369.46 138.062 1369.2 138.319 1368.82 138.319H1362.88C1362.5 138.319 1362.24 138.062 1362.24 137.678V107.913C1362.24 107.529 1362.5 107.273 1362.88 107.273H1368.82C1369.2 107.273 1369.46 107.529 1369.46 107.913V110.177H1369.5C1370.87 108.255 1373.56 106.589 1377.7 106.589C1384.32 106.589 1388.46 111.5 1388.46 118.119V137.721C1388.46 138.105 1388.21 138.361 1387.82 138.361H1381.89V138.319Z"
        fill="#00BEFA"
      />
      <path
        d="M1416.18 138.319C1415.8 138.319 1415.54 138.062 1415.54 137.678V135.372H1415.46C1413.96 137.507 1411.4 139.045 1407.21 139.045C1401.92 139.045 1398.08 136.525 1396.45 131.571C1395.68 129.18 1395.34 127.002 1395.34 122.817C1395.34 118.632 1395.68 116.454 1396.45 114.063C1398.08 109.109 1401.92 106.589 1407.21 106.589C1411.4 106.589 1413.96 108.127 1415.46 110.262H1415.54V95.1874C1415.54 94.8031 1415.8 94.5469 1416.18 94.5469H1422.12C1422.5 94.5469 1422.76 94.8031 1422.76 95.1874V137.721C1422.76 138.105 1422.5 138.361 1422.12 138.361H1416.18V138.319ZM1414.9 128.625C1415.37 127.216 1415.54 125.678 1415.54 122.817C1415.54 119.956 1415.33 118.418 1414.9 117.009C1414.05 114.447 1412.08 112.995 1409.09 112.995C1406.1 112.995 1404.18 114.404 1403.37 117.009C1402.9 118.418 1402.73 119.956 1402.73 122.817C1402.73 125.678 1402.94 127.216 1403.37 128.625C1404.22 131.187 1406.19 132.639 1409.09 132.639C1412 132.639 1414.05 131.23 1414.9 128.625Z"
        fill="#00BEFA"
      />
      <path
        d="M1434.93 107.059V103.387L1431.81 105.266C1431.6 105.394 1431.3 105.308 1431.17 105.095L1430.23 103.387C1430.11 103.173 1430.19 102.874 1430.4 102.746L1433.57 100.995L1430.4 99.2443C1430.19 99.1162 1430.11 98.8172 1430.23 98.6037L1431.17 96.8956C1431.3 96.682 1431.56 96.5966 1431.81 96.7247L1434.97 98.6037V94.9312C1434.93 94.6749 1435.1 94.4614 1435.4 94.4614H1437.32C1437.58 94.4614 1437.79 94.6322 1437.79 94.9312V98.6037L1440.91 96.7247C1441.12 96.5966 1441.42 96.682 1441.55 96.8956L1442.49 98.6037C1442.62 98.8172 1442.53 99.1162 1442.32 99.2443L1439.16 100.995L1442.32 102.746C1442.53 102.874 1442.62 103.13 1442.49 103.387L1441.55 105.095C1441.42 105.308 1441.17 105.394 1440.91 105.266L1437.75 103.387V107.059C1437.79 107.315 1437.62 107.529 1437.32 107.529H1435.4C1435.15 107.529 1434.93 107.358 1434.93 107.059Z"
        fill="#00BEFA"
      />
      <path
        d="M814.775 68.6684C814.391 68.6684 814.134 68.4122 814.134 68.0279V32.071C814.134 31.8148 814.006 31.6867 813.75 31.6867H802.262C801.877 31.6867 801.621 31.4304 801.621 31.0461V25.4946C801.621 25.1102 801.877 24.854 802.262 24.854H833.566C833.951 24.854 834.207 25.1102 834.207 25.4946V31.0461C834.207 31.4304 833.951 31.6867 833.566 31.6867H822.078C821.822 31.6867 821.694 31.8148 821.694 32.071V68.0279C821.694 68.4122 821.437 68.6684 821.053 68.6684H814.732H814.775Z"
        fill="#00BEFA"
      />
      <path
        d="M859.747 68.6684C859.363 68.6684 859.106 68.4122 859.106 68.0279V50.1348C859.106 46.1206 857.099 43.3876 853.255 43.3876C849.412 43.3876 847.319 46.1633 847.319 50.1348V68.0279C847.319 68.4122 847.063 68.6684 846.678 68.6684H840.742C840.358 68.6684 840.102 68.4122 840.102 68.0279V25.4946C840.102 25.1102 840.358 24.854 840.742 24.854H846.678C847.063 24.854 847.319 25.1102 847.319 25.4946V40.5264H847.362C848.728 38.6047 851.419 36.9393 855.562 36.9393C862.181 36.9393 866.324 41.8502 866.324 48.4694V68.0706C866.324 68.4549 866.068 68.7111 865.683 68.7111H859.747V68.6684Z"
        fill="#00BEFA"
      />
      <path
        d="M874.138 60.7251C873.497 58.718 873.027 56.3266 873.027 53.1238C873.027 49.921 873.412 47.4441 874.052 45.437C875.803 40.0136 880.544 36.8535 886.736 36.8535C892.929 36.8535 897.755 40.1417 899.506 45.437C900.232 47.6149 900.531 49.7501 900.531 54.7892C900.531 55.1736 900.275 55.4298 899.805 55.4298H880.672C880.416 55.4298 880.288 55.5579 880.288 55.8141C880.288 56.7109 880.501 57.4796 880.757 58.2056C881.782 61.2376 884.217 62.903 887.719 62.903C891.221 62.903 893.442 61.6219 894.979 59.9564C895.321 59.6148 895.62 59.5721 895.961 59.8283L899.762 63.1592C900.104 63.4155 900.104 63.7571 899.848 64.056C897.2 67.1307 892.844 69.3513 887.078 69.3513C880.501 69.3513 875.889 66.1485 874.138 60.7251ZM892.886 47.0171C892.032 44.4975 889.726 43.0029 886.822 43.0029C883.918 43.0029 881.526 44.4975 880.715 47.0171C880.458 47.7858 880.33 48.5544 880.33 49.6647C880.33 49.921 880.458 50.0491 880.715 50.0491H892.886C893.143 50.0491 893.271 49.921 893.271 49.6647C893.271 48.5544 893.143 47.7858 892.886 47.0171Z"
        fill="#00BEFA"
      />
      <path
        d="M954.685 68.6684C954.301 68.6684 954.045 68.4122 953.916 68.0279L945.546 38.0496H945.418L936.962 68.0279C936.833 68.4122 936.577 68.6684 936.193 68.6684H931.367C930.982 68.6684 930.726 68.4122 930.598 68.0279L919.836 25.4946C919.75 25.1102 919.921 24.854 920.348 24.854H926.413C926.883 24.854 927.139 25.1102 927.182 25.4946L934.143 55.0031H934.271L942.471 25.4946C942.599 25.1102 942.855 24.854 943.24 24.854H947.767C948.236 24.854 948.407 25.1102 948.535 25.4946L956.906 55.0031H957.034L963.782 25.4946C963.867 25.0248 964.166 24.854 964.551 24.854H970.53C970.999 24.854 971.128 25.1102 971.042 25.4946L960.365 68.0279C960.28 68.4122 959.981 68.6684 959.597 68.6684H954.685Z"
        fill="#00BEFA"
      />
      <path
        d="M974.117 60.8105C973.391 58.6326 973.092 56.5401 973.092 53.1238C973.092 49.7074 973.433 47.6576 974.117 45.437C975.868 40.0136 980.608 36.8535 986.929 36.8535C993.25 36.8535 997.948 40.0136 999.699 45.437C1000.42 47.6149 1000.72 49.7074 1000.72 53.1238C1000.72 56.5401 1000.38 58.5899 999.699 60.8105C997.948 66.2339 993.207 69.394 986.929 69.394C980.651 69.394 975.825 66.2339 974.117 60.8105ZM992.737 58.8461C993.25 57.2234 993.378 55.7714 993.378 53.1238C993.378 50.4761 993.25 49.0669 992.737 47.4014C991.84 44.8392 989.833 43.3445 986.929 43.3445C984.025 43.3445 981.975 44.8392 981.078 47.4014C980.566 49.0669 980.437 50.4761 980.437 53.1238C980.437 55.7714 980.566 57.2661 981.078 58.8461C981.975 61.4084 984.025 62.903 986.929 62.903C989.833 62.903 991.84 61.4084 992.737 58.8461Z"
        fill="#00BEFA"
      />
      <path
        d="M1008.41 68.6683C1008.03 68.6683 1007.77 68.4121 1007.77 68.0278V38.263C1007.77 37.8787 1008.03 37.6224 1008.41 37.6224H1014.35C1014.73 37.6224 1014.99 37.8787 1014.99 38.263V40.9107H1015.03C1016.52 38.5192 1019.17 36.8965 1023.1 36.8965C1025.54 36.8965 1028.01 37.8787 1029.59 39.4587C1029.93 39.8004 1029.98 40.0566 1029.72 40.3555L1026.3 44.6686C1026.05 45.0103 1025.71 45.053 1025.41 44.7967C1024 43.9 1022.5 43.3021 1020.84 43.3021C1016.78 43.3021 1014.99 46.206 1014.99 51.1597V67.9851C1014.99 68.3694 1014.73 68.6256 1014.35 68.6256H1008.41V68.6683Z"
        fill="#00BEFA"
      />
      <path
        d="M1072.39 68.6682C1072 68.6682 1071.75 68.412 1071.75 68.0276V65.7216H1071.66C1070.17 67.8568 1067.6 69.3942 1063.42 69.3942C1058.12 69.3942 1054.28 66.8746 1052.66 61.9209C1051.89 59.5295 1051.54 57.3516 1051.54 53.1666C1051.54 48.9816 1051.89 46.8037 1052.66 44.4123C1054.28 39.4586 1058.12 36.939 1063.42 36.939C1067.6 36.939 1070.17 38.4764 1071.66 40.6116H1071.75V25.537C1071.75 25.1527 1072 24.8965 1072.39 24.8965H1078.32C1078.71 24.8965 1078.96 25.1527 1078.96 25.537V68.0703C1078.96 68.4547 1078.71 68.7109 1078.32 68.7109H1072.39V68.6682ZM1071.1 58.9317C1071.57 57.5224 1071.75 55.9851 1071.75 53.1239C1071.75 50.2627 1071.53 48.7254 1071.1 47.3161C1070.25 44.7539 1068.29 43.302 1065.3 43.302C1062.31 43.302 1060.39 44.7112 1059.57 47.3161C1059.1 48.7254 1058.93 50.2627 1058.93 53.1239C1058.93 55.9851 1059.15 57.5224 1059.57 58.9317C1060.43 61.4939 1062.39 62.9458 1065.3 62.9458C1068.2 62.9458 1070.25 61.5366 1071.1 58.9317Z"
        fill="#00BEFA"
      />
      <path
        d="M1086.27 36.6828V25.4516C1086.27 25.0673 1086.52 24.811 1086.91 24.811H1092.12C1092.5 24.811 1092.76 25.0673 1092.76 25.4516V31.5156C1092.76 32.1561 1092.63 32.4978 1092.25 32.8821L1087.85 36.8963C1087.51 37.2379 1087.21 37.366 1086.87 37.366C1086.48 37.366 1086.22 37.1098 1086.22 36.7255L1086.27 36.6828Z"
        fill="#00BEFA"
      />
      <path
        d="M1095.19 64.4833C1094.94 64.2271 1094.94 63.8428 1095.19 63.5866L1098.86 59.7005C1099.12 59.4443 1099.51 59.4443 1099.76 59.7005C1102.03 61.7503 1105.61 63.2449 1108.86 63.2449C1112.66 63.2449 1114.58 61.7076 1114.58 59.5724C1114.58 57.6934 1113.47 56.5404 1109.41 56.156L1106.08 55.8144C1099.76 55.1738 1096.56 51.9283 1096.56 46.6757C1096.56 40.6972 1100.87 36.8965 1108.6 36.8965C1113.43 36.8965 1117.49 38.5192 1120.26 40.6971C1120.6 40.9534 1120.6 41.3377 1120.39 41.5939L1117.23 45.3946C1116.97 45.7362 1116.63 45.7362 1116.33 45.5227C1114.41 44.1562 1111.29 42.9605 1108.22 42.9605C1105.14 42.9605 1103.52 44.327 1103.52 46.1633C1103.52 47.9995 1104.63 49.0671 1108.6 49.4515L1111.93 49.7931C1118.43 50.4337 1121.54 53.8073 1121.54 58.8891C1121.54 65.0812 1117.02 69.3943 1108.52 69.3943C1102.15 69.3943 1097.75 66.8748 1095.19 64.4833Z"
        fill="#00BEFA"
      />
      <path
        d="M1279.09 67.6862L1281.48 53.8501C1281.57 53.2095 1281.18 52.8252 1280.54 52.8252H1266.83C1266.24 52.8252 1265.81 53.2522 1265.68 53.8501L1263.29 67.6862C1263.2 68.2841 1263.59 68.7111 1264.23 68.7111H1277.94C1278.54 68.7111 1278.96 68.2841 1279.09 67.6862Z"
        fill="#00BEFA"
      />
      <path
        d="M1320.22 67.6861L1331.92 1.62282C1332.01 1.02496 1331.62 0.640625 1331.02 0.640625H1317.53C1316.93 0.640625 1316.33 0.854143 1315.73 1.23848L1302.24 9.86471C1301.73 10.249 1301.56 10.6761 1301.43 11.2739L1299.04 25.3663C1298.82 26.1777 1299.42 26.3485 1300.02 25.9642L1312.53 18.2774H1312.75L1303.95 67.7288C1303.86 68.3266 1304.25 68.711 1304.84 68.711H1319.15C1319.75 68.711 1320.13 68.3266 1320.26 67.7288L1320.22 67.6861Z"
        fill="#00BEFA"
      />
      <path
        d="M1202.22 0.853918C1202.22 0.853918 1201.96 0.683105 1201.75 0.683105H1188.21C1187.61 0.683105 1187.48 1.19556 1187.44 1.40908L1180.31 41.5509H1180.05L1166.3 1.45178C1166.09 0.939331 1165.7 0.683105 1165.15 0.683105H1150.8C1150.2 0.683105 1150.07 1.19556 1150.03 1.40908L1138.2 68.1556C1138.2 68.1556 1138.2 68.4545 1138.28 68.5826C1138.37 68.7107 1138.54 68.7534 1138.75 68.7534H1152.29C1152.63 68.7534 1152.89 68.4972 1152.93 68.0275L1160.07 27.8856H1160.41L1174.16 67.9848C1174.29 68.4972 1174.63 68.7534 1175.23 68.7534H1189.7C1190.26 68.7534 1190.43 68.3264 1190.47 68.0275L1202.3 1.28096C1202.3 1.28096 1202.3 0.98203 1202.22 0.853918Z"
        fill="#00BEFA"
      />
      <path
        d="M1238.14 0C1226.31 0 1216.95 4.74016 1211.61 15.6724C1207.98 23.0602 1204.99 41.7646 1204.99 48.2983C1204.99 62.1772 1213.88 69.3942 1227.24 69.3942C1238.99 69.3942 1248.34 64.654 1253.77 53.7218C1257.4 46.3339 1260.39 27.6296 1260.39 21.0958C1260.39 7.21699 1251.5 0 1238.14 0ZM1230.32 61.7501H1230.23V41.5938H1218.28L1235.19 7.64403C1235.19 7.60133 1235.27 7.64403 1235.27 7.64403V27.8004H1247.27L1230.36 61.7501H1230.32Z"
        fill="#00BEFA"
      />
    </svg>
  )
}

export default Ankerslogen
